<template>
  <div
    v-if="isAuthenticated && loaded"
    id="app"
  >
    <PageNav />

    <Overlay class="MainContent">
      <div
        v-if="isGuest"
        class="mx-auto mt-5"
        style="width: 325px"
      >
        <h4>U heeft geen toegang</h4>
        <p>Uw gebruikersaccount heeft geen rechten toegewezen gekregen in de Workflow tool.</p>
        <p>Onze support afdeling kan u verder helpen indien dit onterecht is.</p>
        <p>
          <MailtoSupport />
        </p>
      </div>
      <div v-else>
        <router-view id="scroll-container" />
        <Tour
          v-if="showTour"
          :show-tour-introduction="showTourIntroduction"
          @end-tour="closeTour"
        />
      </div>
    </Overlay>

    <DebugModal v-if="isDebugModalVisible" />

    <PageFooter />

    <!-- <SessionModal /> -->
  </div>

  <!-- Loading screen - loading data -->
  <div
    v-else-if="hasRolesAndAcceptedTerms"
    id="app"
    class="LoadingScreen"
  >
    <div class="MainContent">
      <div class="pt-4">
        <div
          class="m-auto"
          style="width: 160px"
        >
          <EVToolsLogo />
        </div>
        <b-card
          class="m-auto"
          style="width: 350px"
          title="EVtools Workflow"
          sub-title="De datasets worden ingeladen"
        >
          <div
            v-if="!criticalFailure"
            class="LoadingScreen__Status mt-3"
          >
            <div class="d-flex justify-content-between">
              <span>Configuratie...</span>
              <span
                v-if="loadingStatus.config"
                class="done"
              >Done</span>
              <b-spinner
                v-else
                variant="secondary"
                small
              />
            </div>
            <div
              v-if="canAccessRequests"
              class="d-flex justify-content-between"
            >
              <span>Aanvragen...</span>
              <span v-if="getRequestCounts.total">
                <span>{{ getRequestCounts.current }} / {{ getRequestCounts.total }}</span>
              </span>
              <b-spinner
                v-else
                variant="secondary"
                small
              />
            </div>
            <!-- <div v-else class="d-flex justify-content-between">
              <span>Aanvragen...</span>
              <span v-if="loadingStatus.requests" class="done">Done</span>
              <b-spinner v-else variant="secondary" small />
            </div> -->
            <div
              v-if="canAccessRealisationProcesses"
              class="d-flex justify-content-between"
            >
              <span>Realisatie processen...</span>
              <span v-if="getRealisationCounts.total">
                <span>{{ getRealisationCounts.current }} / {{ getRealisationCounts.total }}</span>
              </span>
              <b-spinner
                v-else
                variant="secondary"
                small
              />
            </div>
            <!-- <div v-else class="d-flex justify-content-between">
              <span>Realisatie processen...</span>
              <span v-if="loadingStatus.workflows" class="done">Done</span>
              <b-spinner v-else variant="secondary" small />
            </div> -->
          </div>

          <div v-if="missingMunicipalities.length || missingWorkflows.length || missingRequests.length">
            <p class="pt-4">
              Het laden van
              {{
                loadingStatus.municipalities.error +
                  loadingStatus.pagedWorkflows.error +
                  loadingStatus.pagedRequests.error
              }}
              dataset(s) is mislukt.
            </p>
            <b-button
              v-if="!loading"
              variant="primary"
              @click="handleRetryLoadingDatasets"
            >
              Probeer deze opnieuw
            </b-button>
          </div>

          <div v-if="criticalFailure">
            <p class="pt-4">
              Het laden van de applicatie configuratie is mislukt. Herlaad de pagina opnieuw.
            </p>
            <p>Neem contact op met support indien dit probleem zich blijft voordoen.</p>
          </div>

          <template #footer>
            <div style="font-size: 0.9rem">
              <b-icon
                icon="envelope"
                font-scale="0.9"
                class="mr-1"
              />
              <MailtoSupport class="card-link" />
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>

  <!-- terms check -->
  <div
    v-else-if="rolesAreLoaded && !hasAcceptedTerms"
    id="app"
    class="LoadingScreen"
  >
    <div class="MainContent">
      <div class="pt-4">
        <div
          class="m-auto"
          style="width: 160px"
        >
          <EVToolsLogo />
        </div>
        <b-card
          class="m-auto"
          style="width: 600px"
          title="Gebruikersvoorwaarden"
        >
          <TermsText
            ref="terms"
            @setVersion="termsVersion = $event"
          />
          <div class="text-right">
            <b-spinner
              v-if="termsStatus === 'saving'"
              variant="secondary"
              small
              class="mr-3"
            />
            <b-button
              variant="primary"
              :disabled="termsStatus !== 'pending'"
              @click="handleAccept"
            >
              Ik ga akkoord
            </b-button>
          </div>
          <template #footer>
            <div style="font-size: 0.9rem">
              <b-icon
                icon="envelope"
                font-scale="0.9"
                class="mr-1"
              />
              <MailtoSupport class="card-link" />
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>

  <!-- primary authication check -->
  <div
    v-else
    id="app"
    class="LoadingScreen"
  >
    <div class="MainContent">
      <div class="pt-4">
        <div
          class="m-auto"
          style="width: 160px"
        >
          <EVToolsLogo />
        </div>
        <b-card
          class="m-auto"
          style="width: 275px"
          title="EVtools Workflow"
          sub-title="Authorisatie controle"
        >
          <template #footer>
            <div style="font-size: 0.9rem">
              <b-icon
                icon="envelope"
                font-scale="0.9"
                class="mr-1"
              />
              <MailtoSupport class="card-link" />
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import EVToolsLogo from '@/components/EVToolsLogo.vue';
import PageFooter from '@/components/PageFooter.vue';
import PageNav from '@/components/PageNav.vue';
import Overlay from '@/components/Overlay.vue';
import TermsText, { VERSION } from '@/components/TermsText.vue';
import DebugModal from '@/components/debugger/DebugModal.vue';
import Tour from '@/components/Tour.vue';
import MailtoSupport from '@/components/common/MailtoSupport.vue';
import GeocoderCheck from '@/components/mixins/GeocoderCheck.vue';
import { realisationListFields, tour } from '@/config';
import RealisationProcesses from '@/components/Table/configs/RealisationProcesses';
import { allMunicipalityOptions } from '@/services/allMunicipalityOptions';
import { uuidValidateV4 } from '@/services/validation'

export default {
  name: 'App',
  components: {
    EVToolsLogo,
    PageNav,
    PageFooter,
    Overlay,
    TermsText,
    DebugModal,
    Tour,
    MailtoSupport
  },
  mixins: [GeocoderCheck],
  data() {
    return {
      tour: {},
      showTour: false,
      showTourIntroduction: true,
      criticalFailure: false,
      termsStatus: 'pending',
      loading: false,
      loadingStatus: {
        config: false,
        requests: false,
        workflows: false,
        pagedRequests: {
          error: 0,
          target: 0,
          current: 0
        },
        pagedWorkflows: {
          error: 0,
          target: 0,
          current: 0
        },
        municipalities: {
          error: 0,
          target: 0,
          current: 0
        }
      },
      missingMunicipalities: [],
      missingWorkflows: [],
      missingRequests: [],

      // Flag to indicate the connections between requests & RPs have been established
      establishedConnections: false
      //loadedChargingpoints: [],
    };
  },
  computed: {
    ...mapGetters(['loaded']),
    ...mapGetters('requests', [
      'getRequestCounts',
      'hasRequests',
      'requestByUuid'
    ]),
    ...mapGetters('realisations', {
      realisationprocesses: 'records',
      recordByUuid: 'recordByUuid',
      getRealisationCounts: 'getCounts'
    }),
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses',
      'canAccessAssetManagement',
      'isGuest',
      'roleDetailsMerged',
      'rolesAreLoaded',
      'securityCheck'
    ]),
    ...mapGetters('debug', ['isDebugModalVisible']),
    ...mapGetters('tenant', ['getTenant', 'getMunicipalityOptions', 'getSteps', 'getFeatureAccess', 'getProcessesConfig', 'getProcessTypes']),
    ...mapGetters('chargingpoints', ['chargingpoints']),

    isAuthenticated() {
      return this.$auth.isAuthenticated && this.$auth.user;
    },
    userRolesAreAvailable() {
      return this.isAuthenticated && this.$auth.user[`${process.env.VUE_APP_AUTH_NAMESPACE}roles`];
    },
    subTitle() {
      return this.userRolesAreAvailable ? 'De datasets worden ingeladen' : 'Authorisatie controle';
    },
    usesPagedLoader() {
      return !['amsterdam'].includes(process.env.VUE_APP_TENANT);
    },
    userMunicipalityCode () {
      if (process.env.VUE_APP_TENANT === 'amsterdam') return false

      const isMunicipality = this.$auth.user['https://evtools.nl/roles'].find(role => {
        return role.tenant === process.env.VUE_APP_TENANT &&
               role.municipality.length &&
               ['admin', 'cpo'].every(type => !role[type].length)
      })

      return this.usesPagedLoader && isMunicipality
    },
    restrictedCpoAccess () {
      if (process.env.VUE_APP_TENANT === 'amsterdam') return false

      return this.$auth.user['https://evtools.nl/roles'].find(role => {
        return role.tenant === process.env.VUE_APP_TENANT &&
               role.cpo.length && role.cpo[0] !== '*'
      })
    },
    hasAcceptedTerms() {
      return this.termsStatus === 'accepted';
    },
    // isTourAllowed() {
    //   return this.usesPagedLoader;
    // },
    hasRolesAndAcceptedTerms() {
      return this.rolesAreLoaded && this.hasAcceptedTerms;
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(route) {
        // Temp. replace old realisations route and redirect
        const routeToReplace =
          (route.name === 'Realisation' && !uuidValidateV4({ id: route.params?.uuid })) ||
          (route.name === 'ProcessList' && !route.params.type)

        if (routeToReplace) {
          this.$router.replace({ name: 'ProcessList', params: { type: 'realisatie' } }).catch(() => {})
        }
        this.showTour = false
        // Is there a "routeless" tour feature (route independent) that needs to be shown first //
        const genericFeature = `allRoutes|${tour.date}`
        const routeName = tour.allRoutes && !localStorage.getItem(genericFeature) ? 'allRoutes' : route.name
        this.handleTour({ routeName });
      }
    },
    userRolesAreAvailable(value) {
      if (value) {
        this.init();
      }
    },

    loaded(value) {
      // Trigger Geocoder for requests without coordinates
      if (value === true) {
        // Add steps dynamically to router
        this.addStepsToRouter();

        // Add assetManagement (Locaties) to router if tenant is eligible
        if (this.canAccessRealisationProcesses) {
          this.addAssetManagementRoute();
          this.addProcessStepsToRouter()
        }
        // Check the Aanvragen data
        this.runGeocoder();
      }
    },
    hasRolesAndAcceptedTerms(value) {
      if (value === true) {
        this.$nextTick(this.loadData);
      }
    },
  },
  methods: {
    ...mapActions('tenant', ['loadConfig', 'loadWorkflowSpecs']),
    ...mapActions('realisations', {
      loadRealisations: 'loadRecords'
    }),
    ...mapActions('requests', ['loadRequests', 'loadRequestsByCode']),
    ...mapMutations(['setLoadedState']),
    ...mapMutations('relations', ['addConnection']),
    ...mapMutations('user', ['setRoles']),
    ...mapMutations('chargingpoints', ['replaceChargingPointsByCode']),
    init() {
      if (this.loading || this.loaded) return;

      this.loading = true;

      this.checkTerms();

      this.loadRoles();
      setTimeout(() => {
        this.handleTourAccess();
      }, 1000)
    },

    closeTour () {
      this.showTour = false
      // After end of the generic tour feature, check if there is a tour on the current route
      setTimeout(() => {
        if (!localStorage.getItem(this.$route.name)) {
          this.handleTour({ routeName: this.$route.name });
        }
      }, 100)
    },

    addStepsToRouter() {
      const steps = this.getSteps;
      this.$router.addRoute('Realisation', {
        path: '/type-:type',
        name: 'NewRealisationType',
        component: () => import(`@/views/${steps[0].component}.vue`)
      });

      steps.forEach(step => {
        this.$router.addRoute('Realisation', {
          path: '/realisatie/:uuid/stap-:step',
          name: `realisationStep${step.step || step.short}`,
          component: () => import(`@/views/${step.component}.vue`)
        })
      });
    },

    addProcessStepsToRouter() {
      const componentPath = process.env.VUE_APP_TENANT !== 'amsterdam' ? 'processes' : ''
      const processTypes = Object.keys(this.getProcessesConfig).filter(key => key !== 'realisation')

      processTypes.forEach(type => {
        const steps = this.getProcessesConfig[type].steps

        this.$router.addRoute('Process', {
          path: '/locationId-:locationId-procestype-:type',
          name: `new-${type}`,
          component: () => import(`@/views/${componentPath}/${process.env.VUE_APP_TENANT}/${type}/Step1.vue`)
        });

        steps.forEach((step, index) =>
          this.$router.addRoute('Process', {
            path: '/:type/:uuid/stap-:step',
            name: `${type}Step${index + 1}`,
            component: () => import(`@/views/${componentPath}/${process.env.VUE_APP_TENANT}/${type}/Step${index + 1}.vue`)
          })
        );
      })
    },

    addAssetManagementRoute() {
      this.$router.addRoute('LocationList', {
        path: '/locaties/:locationId?',
        name: 'LocationList',
        component: () => import('@/views/LocationList.vue')
      });
    },

    loadRoles() {
      let roles = this.$auth.user[`${process.env.VUE_APP_AUTH_NAMESPACE}roles`] || {};

      // Older users have only one set of roles, new users have roles per tenant
      if (Array.isArray(roles)) {
        roles = roles.find(role => role.tenant === process.env.VUE_APP_TENANT);
      }

      if (!roles) {
        roles = {
          tenant: process.env.VUE_APP_TENANT,
          admin: [],
          cpo: [],
          municipality: []
        };
      }

      this.setRoles({
        roles
      });
    },

    handleTour({ routeName }) {
      const tourStorage = Object.keys({ ...localStorage });
      this.showTourIntroduction = !tourStorage.some(key => key.includes(tour.date));

      // When History api back/forth is used, tour overlay keeps hanging so we need to remove the class manually //
      document.body.classList.remove('v-tour--active');
      // Check if some Realisation.Step route is already seen

      // This below was never going to work like this. This was originally made for process Step routes.
      // These desctructured assigments below, are all objects and the method "startsWith" is a String prototype
      // and besides that, it can never work with operators (||). This was always going to return false.
      const { LocationList, ProcessList, RequestList } = tour
      const isStepTourSeen = tourStorage.some(key => key.startsWith(LocationList || ProcessList || RequestList));

      //this.showTour = false;

      // Don't initiate the tour if realisation type needs to be selected first //
      // Here the same thing... atm. step routes are not needed anyway. Always false.
      if (routeName?.startsWith(LocationList || ProcessList || RequestList) && !this.recordByUuid({ uuid: this.$route.params.uuid })) return;

      if (tour?.[routeName]) {
        this.$nextTick(() => {
          const steps = tour[routeName].steps;
          // Here the same thing... atm. step routes are not needed anyway. Always false.
          const isTourStepSeen = routeName?.startsWith(LocationList || ProcessList || RequestList) && isStepTourSeen;

          if (isTourStepSeen) {
            // If one of the Realisation.Step routes is already seen, then remove the whole tour //
            // or only the last step in the tour if more are present, to prevent repetition on other RealisationStep tours //
            // if other unseen steps need to be seen

            if (steps?.length === 1 && steps[0].isOnAllSteps) {
              delete tour[routeName];
            } else {
              steps.map((step, index) => {
                if (step.isOnAllSteps) {
                  delete tour[routeName].steps[index];
                }
              });
            }
          }
          this.showTour = !!tour[routeName];
        });
      }
    },

    handleTourAccess() {
      Object.keys(tour).map(route => {
        const feature = tour[route].access;
        if (feature && !this.securityCheck({ attribute: feature })) {
          // Remove whole tour if user has no access to all features it presents //
          return delete tour[route];
        }

        tour[route].steps?.map((step, stepIndex) => {
          if (step.access && !this.securityCheck({ attribute: step.access })) {
            // Remove individual step if user has no access to a feature it presents //
            delete tour[route].steps[stepIndex];
          }
        });
      });
    },

    checkTerms: function () {
      const terms = this.$auth.user[`${process.env.VUE_APP_AUTH_NAMESPACE}terms`] || {};
      const version = VERSION.replaceAll('_', '.');

      if (Object.keys(terms).includes(version)) {
        this.termsStatus = 'accepted';
      }
    },

    handleAccept: async function () {
      try {
        this.termsStatus = 'saving';
        const token = await this.$auth.getTokenSilently();
        const response = await fetch('/api/useracceptterms', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token
          },
          body: JSON.stringify({ version: VERSION })
        });
        if (response.ok) {
          this.termsStatus = 'accepted';
        } else {
          throw new Error('Something went wrong with accepting terms');
        }
      } catch (e) {
        console.error(e);
      }
    },

    loadData: async function () {
      const token = await this.$auth.getTokenSilently();

      try {
        // We need the tenant config & workflow specs while processing the other data
        await this.loadConfig({ token });
        this.setPageTitle();

        await this.loadWorkflowSpecs({ token });
        this.loadingStatus.config = true;
      } catch (e) {
        this.criticalFailure = true;

        return; // Kill the loading process. Game Over.
      }

      if (this.canAccessRequests) {
        if (this.userMunicipalityCode || this.restrictedCpoAccess) {
          const codes = this.userMunicipalityCode?.municipality || this.restrictedCpoAccess?.cpo
          await Promise.all(
            codes.map(code => {
              return this.loadRequestsByCode({ token, code })
                .then(() => {
                  this.loadingStatus.pagedRequests.current++;
                })
                .catch(() => {
                  this.loadingStatus.pagedRequests.error++;
                  this.missingRequests.push(code);
                });
            })
          );

        } else {
          await this.loadRequests({ token })
          .catch(err => console.log('Could not load requests', err))
        }
      }

      if (this.canAccessRealisationProcesses) {
        // enddpoint by municipality used for non-admin municipality user (all but Amsterdam)
        if (this.userMunicipalityCode || this.restrictedCpoAccess) {
          const codes = this.restrictedCpoAccess?.cpo || this.userMunicipalityCode?.municipality

          await Promise.all(
            codes.map(async code => {
              return await this.loadRealisations({ token, code })
                .catch(err => console.log('Could not load realisations for given municipality', err))
            })
          );
        } else {
          await this.loadRealisations({ token })
            .catch(err => console.log('Could not load realisations', err))
        }
        this.setLoadedState({ loaded: true })

        // Remap all realisations to RealisationListFields
        const mappedRecords = this.realisationprocesses.map(RealisationProcesses.dataMapper).slice();
        this.$store.dispatch('realisations/setRealisationTableRecords', {
          records: mappedRecords.map(record => realisationListFields({ record }))
        });
      } else {
        this.$store.dispatch('tenant/setToast', {
          vm: this,
          message: 'Configuratie fout! (Permissies Worflow, geen gemeente geselecteerd)',
          error: false,
          variant: 'warning',
          delay: 100
        });
      }

      if (this.canAccessRequests && this.missingWorkflows.length === 0 && this.missingRequests.length === 0) {
        // If there were no requests with the per municipality approach, try the old method
        // This is currently necessary for PnC, which is missing a municipality reference.
        if (!this.hasRequests) {
          await this.loadRequests({ token });
        }

        this.matchRequestToRealisations();
        this.establishedConnections = true;
      }


      this.loading = false;
    },

    /**
     * Go through the realisation process objects and connect the request objects
     */
    matchRequestToRealisations() {
      if (!this.canAccessRequests && !this.canAccessRealisationProcesses) return;
      // if (! this.isMunicipality) return

      (this.realisationprocesses || []).forEach(record => {
        (record.requestUuids || []).forEach(uuid => {
          this.addConnection({
            requestUuid: uuid,
            realisationUuid: record.uuid
          });
        });
      });
    },

    async loadPagedRequests({ token }) {
      let codes = this.getMunicipalityOptions.filter(muni => muni.disabled === false).map(muni => muni.value);

      if (!this.roleDetailsMerged.includes('*')) {
        codes = codes.filter(code => this.roleDetailsMerged.includes(code));
      }

      this.loadingStatus.pagedRequests.target = codes.length;

      await Promise.all(
        codes.map(code => {
          return this.loadRequestsByCode({ token, code })
            .then(() => {
              this.loadingStatus.pagedRequests.current++;
            })
            .catch(() => {
              this.loadingStatus.pagedRequests.error++;
              this.missingRequests.push(code);
            });
        })
      );
    },

    async loadPagedRealisations({ token }) {
      let codes = allMunicipalityOptions.filter(muni => muni.disabled === false).map(muni => muni.value);

      if (!this.roleDetailsMerged.includes('*')) {
        codes = codes.filter(code => this.roleDetailsMerged.includes(code));
      }

      this.loadingStatus.pagedWorkflows.target = codes.length;

      await Promise.all(
        codes.map(code => {
          return this.loadRealisations({ token, code })
            .then(() => {
              this.loadingStatus.pagedWorkflows.current++;
            })
            .catch(() => {
              this.loadingStatus.pagedWorkflows.error++;
              this.missingWorkflows.push(code);
            });
        })
      );
    },

    /**
     * Load chargingpoints for all active municipalities
     *  One by one, but all at once
     */
    async loadChargingPoints() {
      let codes = allMunicipalityOptions.filter(muni => muni.disabled === false).map(muni => muni.value);

      if (!this.roleDetailsMerged.includes('*')) {
        codes = codes.filter(code => this.roleDetailsMerged.includes(code));
      }

      this.loadingStatus.municipalities.target = codes.length;

      await Promise.all(
        codes.map(code => {
          return this.loadChargingPointsByCode({ code })
            .then(() => {
              this.loadingStatus.municipalities.current++;
            })
            .catch(() => {
              this.loadingStatus.municipalities.error++;
              this.missingMunicipalities.push(code);
            });
        })
      );
    },

    /**
     *
     */
    async loadChargingPointsByCode({ code, after }) {
      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/chargingpoints', {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          codes: [code],
          after
        })
      });
      if (!api.ok) {
        throw new Error('failed');
      }
      const response = await api.json();

      if (response.data?.chargingpoints) {
        this.loadedChargingpoints = [...this.loadedChargingpoints, ...response.data.chargingpoints];
        this.replaceChargingPointsByCode({ code, chargingpoints: response.data.chargingpoints });

        if (response.data.after) {
          return await this.loadChargingPointsByCode({ code, after: response.data.after });
        }
      }

      return response;
    },

    /**
     *
     */
    async handleRetryLoadingDatasets() {
      await this.handleRetryRequests();
      await this.handleRetryWorkflows();
      await this.handleRetryMunicipalities();

      if (
        this.establishedConnections === false &&
        this.missingWorkflows.length === 0 &&
        this.missingRequests.length === 0
      ) {
        this.matchRequestToRealisations();
        this.establishedConnections = true;
      }

      if (
        this.missingMunicipalities.length === 0 &&
        this.missingWorkflows.length === 0 &&
        this.missingRequests.length === 0
      ) {
        this.setLoadedState({ loaded: true });
      }
    },

    async handleRetryMunicipalities() {
      this.loading = true;
      let failed = [];
      await Promise.all(
        this.missingMunicipalities.map(code => {
          return this.loadChargingPointsByCode({ code })
            .then(() => {
              this.loadingStatus.municipalities.current++;
              this.loadingStatus.municipalities.error--;
            })
            .catch(() => {
              failed.push(code);
            });
        })
      );
      this.missingMunicipalities = failed;
      this.loading = false;
    },

    async handleRetryWorkflows() {
      const token = await this.$auth.getTokenSilently();
      this.loading = true;
      let failed = [];

      await Promise.all(
        this.missingWorkflows.map(code => {
          return this.loadRealisations({ token, code })
            .then(() => {
              this.loadingStatus.pagedWorkflows.current++;
              this.loadingStatus.pagedWorkflows.error--;
            })
            .catch(() => {
              failed.push(code);
            });
        })
      );
      this.missingWorkflows = failed;
      this.loading = false;
    },

    async handleRetryRequests() {
      const token = await this.$auth.getTokenSilently();
      this.loading = true;
      let failed = [];

      await Promise.all(
        this.missingRequests.map(code => {
          return this.loadRequestsByCode({ token, code })
            .then(() => {
              this.loadingStatus.pagedRequests.current++;
              this.loadingStatus.pagedRequests.error--;
            })
            .catch(() => {
              failed.push(code);
            });
        })
      );
      this.missingRequests = failed;
      this.loading = false;
    },

    // updateAddressData() {
    //   this.realisationprocesses.forEach(record => {
    //     record.address = record.generateAddressData({ model: record });
    //   });
    // },

    /**
     * Set the page title (seen in browser tabs)
     */
    setPageTitle() {
      let title = null;

      if (title) {
        document.title = title;
      }
    }
  }
};
</script>

<style lang="scss">
html,
body {
  // height: 100%;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.MainContent {
  // overflow-y: scroll;
  min-height: 100vh;
  padding-top: 52px; // 52px offset for the header
  padding-bottom: 25px; // 25px offset for footer
}

.LoadingScreen {
  background: #041e42;

  .card {
    box-shadow: 0 0 40px 4px #111118;
  }

  &__Status .done {
    color: #00b46b;
  }
}

.v-tour--active {
  .DetailGrid__sidebar {
    position: relative;
    z-index: 3;
  }

  .v-step__header__close {
    font-size: 16px;
    margin: 0;
    right: -0.8em;
    top: -0.1em;
    position: relative;
  }
}

.v-tour__target--highlighted {
  padding: 1em;

  legend {
    >span {
      display: inline-block;
      position: relative;
      top: 1em;
    }
  }
}

.Reporting+div {
  .v-tour {
    .v-step {
      max-width: 500px;
      min-width: 400px;

      .v-step__header {
        width: auto;
      }
    }
  }
}
</style>
